@keyframes flashAnimation {
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
}

div.Wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
}

div.Container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

canvas.Canvas {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

video.Video {
  position: absolute;

  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
}

div.Overlay {
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  box-shadow: 0px 0px 20px 56px rgba(0, 0, 0, 0.6);
  border: 1px solid #ffffff;
  border-radius: 10px;
}

div.Flash {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  opacity: 0;
}

button.Button {
  width: 75%;
  min-width: 100px;
  max-width: 250px;
  margin-top: 24px;
  padding: 12px 24px;
  background: silver;
}
